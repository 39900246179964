import { useGetPreviousPageSessionState } from "raci-react-library";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ACCEPT_SETTLEMENT } from "../../constants";
import { transactionState } from "../../recoil/atoms";
import {
  RouteInformation,
  cashSettlementAcceptRoutes,
  cashSettlementDeclineRoutes,
  eftRoutes,
  loadingRoutes,
  utilityRoutes,
} from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesResults {
  steps: StepInformation[];
  formRoutes: RouteInformation[];
  activeStepIndex?: number;
  totalStepCount?: number;
  isClaimsFlow: boolean;
  navigateToPreviousStep?: () => void;
  canNavigateToPreviousStep: boolean;
}

export const useRoutes = (): UseRoutesResults => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTransaction = useRecoilValue(transactionState);
  const { path: previousPageUrl } = useGetPreviousPageSessionState();

  const isCashSettlementFlow = currentTransaction.isCashSettlementFlow;

  const getSettlementRoutes = () => {
    return currentTransaction.selectedCashSettlementOption === ACCEPT_SETTLEMENT
      ? cashSettlementAcceptRoutes
      : cashSettlementDeclineRoutes;
  };

  const steps = currentTransaction.tokenQuery
    ? isCashSettlementFlow
      ? getSettlementRoutes()
      : eftRoutes
    : loadingRoutes;

  const isUtilityRoute = utilityRoutes.filter((item) => item.path === location.pathname).length > 0;

  const activeStepIndex = isUtilityRoute ? undefined : steps.findIndex((item) => item.path === location.pathname);

  const totalStepCount = isUtilityRoute ? undefined : steps.length;

  const navigateToPreviousStep = useMemo(() => {
    return !isUtilityRoute && previousPageUrl && (activeStepIndex ?? 0) > 0
      ? () => navigate(previousPageUrl, { replace: true })
      : undefined;
  }, [navigate, isUtilityRoute, activeStepIndex, previousPageUrl]);

  const canNavigateToPreviousStep =
    !!navigateToPreviousStep && steps.indexOf(steps[steps.length - 1]) !== activeStepIndex && activeStepIndex !== 0;

  return {
    steps,
    formRoutes: steps,
    activeStepIndex,
    totalStepCount,
    isClaimsFlow: !isUtilityRoute,
    canNavigateToPreviousStep,
    navigateToPreviousStep,
  };
};

export default useRoutes;
