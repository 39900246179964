import { OpenBackdrop } from "raci-react-library";
import React, { Suspense } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import useSessionExpiry from "../shared/hooks/useSessionExpiry";
import useStandardPage from "../shared/hooks/useStandardPage";
import useStepperPage from "../shared/hooks/useStepperPage";
import RouteGuard from "../shared/routing/RouteGuard";
import { TitleGuard } from "../shared/routing/TitleGuard";
import { utilityRoutes } from "../shared/routing/routes.config";
import useRoutes from "../shared/routing/useRoutes";

const RacwaStepperPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStepperTemplate })),
);
const RacwaStandardPageTemplate = React.lazy(() =>
  import("@racwa/react-components").then((module) => ({ default: module.RacwaStandardPageTemplate })),
);

export const Views: React.FC = () => {
  useSessionExpiry();
  const { formRoutes } = useRoutes();
  const stepperPageProps = useStepperPage();
  const standardPageProps = useStandardPage();

  return (
    <>
      <TitleGuard />
      <RouteGuard disableChecks={utilityRoutes.map((item) => item.path)} />
      <Routes>
        <Route
          path={process.env.REACT_APP_HOMEPAGE}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStepperPageTemplate {...stepperPageProps}>
                <Outlet />
              </RacwaStepperPageTemplate>
            </Suspense>
          }
        >
          {formRoutes.map(({ key, path, element }) => (
            <Route key={key} path={path} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}

          <Route path="*" element={<Navigate to={formRoutes[0].path} replace />} />
          <Route path={process.env.REACT_APP_HOMEPAGE} element={<Navigate to={formRoutes[0].path} replace />} />
        </Route>

        <Route
          path={process.env.REACT_APP_HOMEPAGE}
          element={
            <Suspense fallback={<OpenBackdrop />}>
              <RacwaStandardPageTemplate {...standardPageProps}>
                <Outlet />
              </RacwaStandardPageTemplate>
            </Suspense>
          }
        >
          {utilityRoutes.map(({ key, element }) => (
            <Route key={key} path={key} element={<Suspense fallback={<OpenBackdrop />}>{element}</Suspense>} />
          ))}
          <Route path="*" element={<Navigate to={formRoutes[0].path} replace />} />
        </Route>

        <Route path="*" element={<Navigate to={formRoutes[0].path} replace />} />
      </Routes>
    </>
  );
};

export default Views;
