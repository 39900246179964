import { LoadingStatus } from "raci-react-library";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTE_ERROR, SESSION_KEY_PREFIX } from "../../constants";
import useApiClient from "../useApiClient";

// Note: Even though SESSION_KEY_PREFIX is defined as a const
// and should have been initialised prior to
// this module running, it is appearing as undefined (only for this module)
const sessionPrefix = SESSION_KEY_PREFIX || "RAC_claimseft_";
export const SESSION_ID_KEY = `${sessionPrefix}SessionId`;

export const useCreateSession = (): LoadingStatus => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const client = useApiClient();
  const navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false;

    const createSession = async () => {
      setIsError(false);
      try {
        if (!isCancelled && !sessionStorage.getItem(SESSION_ID_KEY)) {
          const response = await client.createSession();
          sessionStorage.setItem(SESSION_ID_KEY, response.result);
        }
      } catch {
        setIsError(true);
        navigate(`${process.env.PUBLIC_URL}/${ROUTE_ERROR}`, { replace: true });
      } finally {
        setIsLoading(false);
      }
    };

    createSession();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? LoadingStatus.Loading : isError ? LoadingStatus.Error : LoadingStatus.Successful;
};

export default useCreateSession;
