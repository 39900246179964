import { SecureTokenResponse } from "raci-claimseft-clientproxy";
import { atom } from "recoil";
import { AcceptOrDecline } from "../../views/ChooseSettlementOption/chooseSettlementOptiontypes";
import { SESSION_KEY_HAS_USER_REQUESTED_CALL, SESSION_KEY_PREFIX } from "../constants";

export interface TransactionState {
  isCashSettlementFlow: boolean;
  isEftSettlementFlow: boolean;
  tokenQuery: string | null;
  secureToken: SecureTokenResponse | null;
  selectedCashSettlementOption: AcceptOrDecline | null;
  isConfirmed: boolean;
  isMobile: boolean;
  isFirstLandlineRequest: boolean;
  hasMoreAttempts: boolean;
  otpError: string | null;
  maskedPhoneNumber: string;
  canVerify: boolean;
  roadBlockType: string | null;
  isSystemUnavailable: boolean;
  isClaimTransactionNumUnavailable: boolean;
  detailsAlreadyProvided: boolean;
  isSessionTimeout: boolean;
  clearOtp: boolean;
  transactionIds: string[];
}

export const transactionState = atom<TransactionState>({
  key: `${SESSION_KEY_PREFIX}transactionState`,
  default: {
    isCashSettlementFlow: false,
    isEftSettlementFlow: false,
    tokenQuery: null,
    secureToken: null,
    selectedCashSettlementOption: null,
    isConfirmed: false,
    isMobile: true,
    isFirstLandlineRequest: true,
    hasMoreAttempts: true,
    otpError: null,
    maskedPhoneNumber: "",
    canVerify: false,
    roadBlockType: null,
    isSystemUnavailable: false,
    isClaimTransactionNumUnavailable: false,
    detailsAlreadyProvided: false,
    isSessionTimeout: false,
    clearOtp: false,
    transactionIds: [],
  },
});

export const hasUserRequestedPhoneCallAtom = atom<boolean>({
  key: SESSION_KEY_HAS_USER_REQUESTED_CALL,
  default: false,
});
