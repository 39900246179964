import { useState } from "react";
import { SESSION_KEY_PREFIX } from "../../constants";
import { SESSION_ID_KEY } from "../useCreateSession";

const prefix = `${SESSION_KEY_PREFIX}PAGE_` || "RAC_CEFT_PAGE_";

export const clearSession = (...excludeKeys: (string | undefined)[]): void => {
  for (let i = sessionStorage.length - 1; i >= 0; i--) {
    const key = sessionStorage.key(i) || "";
    if (!(key === SESSION_ID_KEY) && key.startsWith(SESSION_KEY_PREFIX) && !excludeKeys.includes(key)) {
      sessionStorage.removeItem(key);
    }
  }
};

const deepCopy = (obj: any) => {
  let copy: any;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || "object" != typeof obj) {
    return obj;
  }

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (let i = 0, len = obj.length; i < len; i++) {
      copy[i] = deepCopy(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (const attr in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, attr)) copy[attr] = deepCopy(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
};

export interface SessionState {
  page?: string | null;
  isCompleted?: boolean | null;
  otpVerifyAttempts?: number;
}

const extractPageIndexFromKey = (key: string): number => {
  const matches = key.match(`_(\\d+)_`);
  return (matches && matches.length > 1 && parseInt(matches[1])) || 0;
};

const sortSessionKeysByPageIndexKey = (aKey: string, bKey: string): number =>
  extractPageIndexFromKey(aKey) - extractPageIndexFromKey(bKey);

const getPageSessionStorageKeys = (): string[] => {
  const result: string[] = [];
  for (let i = 0; i < sessionStorage.length; i++) {
    const sessionKey = sessionStorage.key(i);
    if (sessionKey?.startsWith(prefix)) {
      result.push(sessionKey);
    }
  }

  return result.sort(sortSessionKeysByPageIndexKey);
};

const getPageIndex = (key: string): number => {
  const currentPageKeys = getPageSessionStorageKeys();
  const match = currentPageKeys.find((item: string) => item.endsWith(`_${key}`));
  return match ? extractPageIndexFromKey(match) : currentPageKeys.length;
};

const resetIsCompletedOnProceedingPages = (currentPage: string) => {
  const pages = getPageSessionStorageKeys();
  let performUpdate: boolean = false;

  for (const key of pages) {
    if (performUpdate) {
      const data = JSON.parse(sessionStorage.getItem(key) || "{}");
      data.isCompleted = false;
      sessionStorage.setItem(key, JSON.stringify(data));
    }
    if (key === currentPage) {
      performUpdate = true;
    }
  }
};

const getPageName = () => {
  const location = window.location.href;
  return location.substring(location.lastIndexOf("/") + 1);
};

export const generatePageKey = (key: string) => `${prefix}${getPageIndex(key)}_${key}`;

const getCurrentPageKey = (): string => {
  const page = getPageName();
  return generatePageKey(page);
};

const getCurrentPageIndex = (): number => {
  const page = getPageName();
  return getPageIndex(page);
};

export const useSessionStateInitialiser = () => (pages: string[]) => {
  pages.forEach((key: string, index: number) => {
    const existingPageKey = generatePageKey(key);
    const newPageKey = `${prefix}${index}_${key}`;
    const existingData = sessionStorage.getItem(existingPageKey);

    const newData = JSON.parse(existingData || "{}");
    newData.page = key;

    if (existingData && existingPageKey !== newPageKey) {
      sessionStorage.removeItem(existingPageKey);
    }

    if (!sessionStorage.getItem(newPageKey)) {
      sessionStorage.setItem(newPageKey, JSON.stringify(newData));
    }
  });
};

export const useGetSessionState = <T extends SessionState>(key: string): T => {
  const pageKey = generatePageKey(key);
  return JSON.parse(sessionStorage.getItem(pageKey) || "{}") as T;
};

export const useGetPreviousPageSessionState = <T extends SessionState>(): T => {
  const pages = getPageSessionStorageKeys();
  const currentPageIndex = getCurrentPageIndex();
  return currentPageIndex < 1
    ? ({} as T)
    : (JSON.parse(sessionStorage.getItem(pages[currentPageIndex - 1]) || "{}") as T);
};

export interface UseSessionStateOptions {
  specificKey?: string;
  skipPageTrackingRecalculation?: boolean | false;
}

export const useSessionState = <T extends SessionState>({
  specificKey,
  skipPageTrackingRecalculation = false,
}: UseSessionStateOptions | undefined = {}): [T, (newValue: T) => void] => {
  const pageKey = specificKey ? generatePageKey(specificKey) : getCurrentPageKey();
  const [state, setState] = useState<T>(JSON.parse(sessionStorage.getItem(pageKey) || "{}"));
  const setValue = (newValue: T) => {
    const copy = deepCopy(newValue) as T;
    sessionStorage.setItem(pageKey, JSON.stringify({ ...copy, page: getPageName() }));
    setState(copy);
    if (!skipPageTrackingRecalculation) {
      resetIsCompletedOnProceedingPages(pageKey);
    }
  };

  return [state, setValue];
};

export default useSessionState;
