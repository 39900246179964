import { Action, BrowserHistory, createBrowserHistory } from "history";
import { useGetPreviousPageSessionState } from "raci-react-library";
import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useGetSessionState } from "../../hooks/useSessionState";
import { transactionState } from "../../recoil/atoms";
import { ClaimsRoutes, UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL } from "../routes.config";

export interface RouteGuardProps {
  disableChecks?: string[];
  children?: React.ReactNode;
}

export const RouteGuard: React.FC<RouteGuardProps> = ({ disableChecks = [] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isSystemUnavailable } = useRecoilValue(transactionState);
  const { path: previousPageUrl, isCompleted: isPreviousPageCompleted } = useGetPreviousPageSessionState();
  const { isCompleted: isOtpCompleted } = useGetSessionState(ClaimsRoutes.Verification);

  const historyRef = useRef<BrowserHistory>();
  if (historyRef.current == null) {
    historyRef.current = createBrowserHistory({ window });
  }
  const history = historyRef.current;

  /**
   * The below will check if the current page can
   * be navigated to by checking if the previous
   * page has been completed.
   */
  useEffect(() => {
    const currentLocation = location.pathname.toLowerCase();
    const bypass = disableChecks.filter((item) => item.toLowerCase() === currentLocation).length > 0;
    if (!bypass && previousPageUrl && !isPreviousPageCompleted) {
      navigate(previousPageUrl, { replace: true });
    }

    if (isSystemUnavailable && currentLocation !== UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL.toLowerCase()) {
      navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, { replace: true });
    }
  }, [navigate, disableChecks, previousPageUrl, isPreviousPageCompleted, location.pathname, isSystemUnavailable]);

  useEffect(() => {
    const unlisten = history.listen(({ action, location: loc }) => {
      if (action !== Action.Pop) return;

      //If we have completed the OTP, and try to POP (navigate back), then send user to error page
      if (isOtpCompleted) {
        navigate(UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL, { replace: true });
      }
    });
    return unlisten;
  }, [navigate, history, isOtpCompleted]);

  return null;
};

export default RouteGuard;
