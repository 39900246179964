import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop, ScrollToTop, SessionStatePrefixProvider, SessionStatusProvider, WebChat } from "raci-react-library";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import RacwaValidateSecureToken from "./shared/components/RacwaValidateSecureToken/RacwaValidateSecureToken.component";
import { SESSION_KEY_PAGE_PREFIX } from "./shared/constants";
import useCreateSession from "./shared/hooks/useCreateSession";
import useGetWebChatInformation from "./shared/hooks/useGetWebChatInformation";
import Views from "./views";

export const App: React.FC = () => (
  <RacwaThemeProvider>
    <RecoilRoot>
      <Backdrop />
      <BrowserRouter>
        <SessionStatePrefixProvider prefix={SESSION_KEY_PAGE_PREFIX}>
          <SessionStatusProvider useCreateSession={useCreateSession}>
            <RacwaValidateSecureToken />
            <ScrollToTop />
            <Views />
          </SessionStatusProvider>
          <WebChat useGetWebChatInformation={useGetWebChatInformation} />
        </SessionStatePrefixProvider>
      </BrowserRouter>
    </RecoilRoot>
  </RacwaThemeProvider>
);

export default App;
