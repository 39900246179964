import { ClaimsEftApiException, SecureTokenResponse } from "raci-claimseft-clientproxy";
import { HTTP_STATUS_CODE_NOT_FOUND, useSetBackdrop } from "raci-react-library";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HTTP_STATUS_OK } from "../../constants";
import useApiClient from "../useApiClient";

export const useValidateSecureToken = () => {
  const [isError, setIsError] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isCashFlow, setIsCashFlow] = useState(false);
  const [isEftFlow, setIsEftFlow] = useState(false);
  const [isSystemUnavailable, setIsSystemUnavailable] = useState(false);
  const [isNoFurtherAction, setIsNoFurtherAction] = useState(false);
  const [isClaimNotFound, setIsClaimNotFound] = useState(false);
  const [token, setToken] = useState<string | null>(null);
  const [decryptedToken, setDecryptedToken] = useState<SecureTokenResponse | null>(null);
  const client = useApiClient();
  const location = useLocation();
  const setBackdrop = useSetBackdrop();

  useEffect(() => {
    let isCancelled = false;

    const validateSecureToken = async () => {
      setIsError(false);
      setBackdrop(true);
      try {
        const parsedQueryString = new URLSearchParams(location.search);
        const parsedToken = parsedQueryString.get("token");

        if (!parsedToken) {
          setIsInvalid(true);
          return;
        }

        setToken(parsedToken);

        if (!isCancelled) {
          const response = await client.validateSecureToken(parsedToken);

          if (response.status === 200) {
            const transactionIds = response.result.transactionIds ?? [];

            if (transactionIds.length > 0) {
              try {
                const responseDetails = await client.claimTransaction(transactionIds[0]);
                if (responseDetails.status === HTTP_STATUS_OK && responseDetails.result.transactionFlow) {
                  const flowNode = responseDetails.result.transactionFlow;

                  if (flowNode.noFurtherActionRequired) {
                    client.consumeSecureToken(parsedToken);
                    setIsNoFurtherAction(true);
                  } else if (flowNode.cashSettlement || flowNode.eftSettlement) {
                    setIsCashFlow(!!flowNode.cashSettlement);
                    setIsEftFlow(!!flowNode.eftSettlement);
                  } else {
                    setIsSystemUnavailable(true);
                    //consume token for Storno or Rejected transaction status
                    if (flowNode.transactionErrorState) {
                      client.consumeSecureToken(parsedToken);
                    }
                  }
                }
              } catch (ex) {
                const response = ex as ClaimsEftApiException;

                if (response.status === HTTP_STATUS_CODE_NOT_FOUND) {
                  // consume the sts token as per SPK-5139
                  try {
                    client.consumeSecureToken(parsedToken);
                    setIsClaimNotFound(true);
                  } catch (ex) {
                    // if there is an expectation consuming the token for any reason go to system error
                    setIsSystemUnavailable(true);
                  }
                } else {
                  setIsSystemUnavailable(true);
                }
              }
            }
            setDecryptedToken(response.result);
          }
        }
      } catch (ex) {
        const response = ex as ClaimsEftApiException;
        if (response?.status === 400) {
          setIsInvalid(true);
        } else {
          setIsError(true);
        }
      } finally {
        setBackdrop(false);
      }
    };

    validateSecureToken();

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isError,
    isInvalid,
    token,
    decryptedToken,
    isCashFlow,
    isEftFlow,
    isSystemUnavailable,
    isNoFurtherAction,
    isClaimNotFound,
  };
};

export default useValidateSecureToken;
