import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { allRoutes } from "../routes.config";
import useRoutes from "../useRoutes";

const defaultTitle = process.env.REACT_APP_SITE_TITLE || "";
const envPrefix = process.env.REACT_APP_ENVIRONMENT !== "PRD" ? `**${process.env.REACT_APP_ENVIRONMENT}** - ` : "";

const createPageTitle = (routeName: string | undefined, currentStepNumber: number) => {
  if (!routeName) {
    return defaultTitle;
  }

  return currentStepNumber > 0
    ? `${envPrefix}Claim settlement - Step ${currentStepNumber} - ${routeName}`
    : `${envPrefix}Claim settlement - ${routeName}`;
};

export const TitleGuard: React.FC = () => {
  const location = useLocation();
  const { steps } = useRoutes();

  useEffect(() => {
    if (document) {
      const currentRoute = allRoutes.find((item) => item.path === location.pathname);
      const currentStep = steps.find((item) => item.path === location.pathname);
      const currentStepNumber = currentStep ? steps.indexOf(currentStep) + 1 : -1;

      document.title = createPageTitle(currentRoute?.name, currentStepNumber);
    }
  }, [location.pathname, steps]);

  return null;
};
