import { useEffect, useRef, useState } from "react";

export const useInterval = (callback: Function, delayInMilliseconds?: number | null) => {
  const [delay, setDelay] = useState(delayInMilliseconds || null);
  const savedCallback = useRef<Function>(() => {});
  const interval = useRef<NodeJS.Timeout>();

  const updateDelay = (newDelay: number) => {
    setDelay(newDelay);
  };

  const forceStop = () => {
    setDelay(null);

    if (interval.current) {
      clearInterval(interval.current);
    }
  };

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay) {
      interval.current = setInterval(() => savedCallback.current(), delay);
      return () => {
        if (interval.current) {
          clearInterval(interval.current);
        }
      };
    }
    return undefined;
  }, [delay]);

  return { updateDelay, forceStop };
};

export default useInterval;
