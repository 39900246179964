import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  ROUTE_CLAIM_TRANSACTION_NOT_FOUND,
  ROUTE_ERROR,
  ROUTE_EXPIRED,
  ROUTE_LOADING,
  ROUTE_NO_FURTHER_ACTION,
} from "../../constants";
import useValidateSecureToken from "../../hooks/useValidateSecureToken";
import { transactionState } from "../../recoil/atoms";

const RacwaValidateSecureToken: React.FC = () => {
  const {
    isInvalid,
    isError,
    token,
    decryptedToken,
    isCashFlow,
    isEftFlow,
    isNoFurtherAction,
    isSystemUnavailable,
    isClaimNotFound,
  } = useValidateSecureToken();
  const navigate = useNavigate();
  const [currentTransactionState, setTransactionState] = useRecoilState(transactionState);

  const redirectToLandingPage = () => navigate(`${process.env.PUBLIC_URL}/${ROUTE_LOADING}`);
  const redirectToLinkExpiredPage = () => navigate(`${process.env.PUBLIC_URL}/${ROUTE_EXPIRED}`);
  const redirectToSystemUnavailablePage = () => navigate(`${process.env.PUBLIC_URL}/${ROUTE_ERROR}`);
  const redirectToNoFurtherActionPage = () => navigate(`${process.env.PUBLIC_URL}/${ROUTE_NO_FURTHER_ACTION}`);
  const redirectToClaimNotFoundPage = () => navigate(`${process.env.PUBLIC_URL}/${ROUTE_CLAIM_TRANSACTION_NOT_FOUND}`);

  useEffect(() => {
    redirectToLandingPage();

    if (isInvalid) {
      redirectToLinkExpiredPage();
    } else if (isError || isSystemUnavailable) {
      redirectToSystemUnavailablePage();
    } else if (isNoFurtherAction) {
      redirectToNoFurtherActionPage();
    } else if (isClaimNotFound) {
      redirectToClaimNotFoundPage();
    } else if (decryptedToken) {
      setTransactionState({
        ...currentTransactionState,
        isCashSettlementFlow: isCashFlow,
        isEftSettlementFlow: isEftFlow,
        tokenQuery: token,
        secureToken: decryptedToken,
        transactionIds: decryptedToken.transactionIds ?? [],
      });

      navigate(process.env.PUBLIC_URL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isInvalid, token, decryptedToken]);

  return <></>;
};

export default RacwaValidateSecureToken;
