import { ClaimsEftApiException } from "raci-claimseft-clientproxy";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_ENTER_EFT, ROUTE_LOADING, ROUTE_SELECT_OPTION, ROUTE_VERIFICATION } from "../../constants";
import { UTILITY_SESSION_TIMEOUT_PAGE_URL } from "../../routing/routes.config";
import useApiClient from "../useApiClient";
import useInterval from "../useInterval";

export const HTTP_STATUS_CODE_TIMEOUT = 408;
export const EXPIRY_CHECK_INTERVAL = 30000;

export const useSessionExpiry = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApiClient();
  const [expireInSeconds, setExpireInSeconds] = useState<number | null>(null);
  const [isError, setIsError] = useState(false);

  const getNextDelay = (expiry: number) => Math.max(expiry * 500, EXPIRY_CHECK_INTERVAL);

  const { updateDelay, forceStop } = useInterval(() => {
    setIsError(false);

    const checkSessionExpiry = async () => {
      try {
        const response = await client.getSessionExpiry();

        console.log("Session expiry: ", response.result);

        const expiry = parseInt(response.result);
        updateDelay(getNextDelay(expiry));
        setExpireInSeconds(expiry);
      } catch (ex) {
        const response = ex as ClaimsEftApiException;
        if (response?.status === HTTP_STATUS_CODE_TIMEOUT) {
          updateDelay(getNextDelay(-1));
          setExpireInSeconds(-1);
          navigate(UTILITY_SESSION_TIMEOUT_PAGE_URL, { replace: true });
        } else {
          setIsError(true);
        }
      }
    };

    const pageCanTimeout =
      location.pathname.endsWith(ROUTE_LOADING) ||
      location.pathname.endsWith(ROUTE_SELECT_OPTION) ||
      location.pathname.endsWith(ROUTE_VERIFICATION) ||
      location.pathname.endsWith(ROUTE_ENTER_EFT);

    if (pageCanTimeout) {
      checkSessionExpiry();
    } else {
      forceStop();
    }
  }, EXPIRY_CHECK_INTERVAL);

  return {
    isError,
    expireInSeconds,
  };
};

export default useSessionExpiry;
