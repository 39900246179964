import { MobileStepperProps, RacwaStepperTemplateProps } from "@racwa/react-components";
import { EMPTY_URL, SidebarContent } from "raci-react-library";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { transactionState } from "../../recoil/atoms";
import useRoutes from "../../routing/useRoutes";
import usePage from "../usePage";

export const useStepperPage = (): RacwaStepperTemplateProps => {
  const commonPageProps = usePage();
  const { steps, activeStepIndex, canNavigateToPreviousStep, navigateToPreviousStep } = useRoutes();
  const navigate = useNavigate();
  const currentTransaction = useRecoilValue(transactionState);

  const isLoading = !currentTransaction.tokenQuery;
  const isCashSettlementFlow = currentTransaction.isCashSettlementFlow;
  const sidebarTitle = isCashSettlementFlow ? "Claim settlement" : "Bank details";

  const sidebarContent = useMemo(() => <SidebarContent pdsUrl={process.env.REACT_APP_PDS_PED_URL || EMPTY_URL} />, []);

  const mobileStepperProps: MobileStepperProps = {
    hideBack: !canNavigateToPreviousStep,
    onClickBack: () => navigateToPreviousStep && navigateToPreviousStep(),
  };

  const onStepClick = (stepName: string, stepIndex: number) => {
    activeStepIndex !== stepIndex && navigate(steps[stepIndex].path);
  };

  return {
    sidebarTitle: isLoading ? "" : sidebarTitle,
    sidebarContent,
    steps: isLoading ? [] : steps,
    activeStepIndex,
    mobileStepperProps,
    onStepClick,
    ...commonPageProps,
  };
};

export default useStepperPage;
