import { lazy } from "react";
import { ROADBLOCK_TYPE_ERROR, ROADBLOCK_TYPE_VERIFY } from "../constants";

const LandingPage = lazy(() => import("../../views/LandingPage/LandingPage.component"));
const ChooseSettlementOption = lazy(() => import("../../views/ChooseSettlementOption/ChooseSettlementOption"));
const OtpVerification = lazy(() => import("../../views/OtpVerification/OtpVerification"));
const EnterBankDetails = lazy(() => import("../../views/EnterBankDetails/EnterBankDetails.component"));
const Confirmation = lazy(() => import("../../views/Confirmation"));

const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));
const LinkExpired = lazy(() => import("../../views/LinkExpired"));
const RoadBlock = lazy(() => import("../../views/Roadblock"));
const ClaimTransactionNotFound = lazy(() => import("../../views/ClaimTransactionNotFound"));
const NoFurtherAction = lazy(() => import("../../views/NoFurtherAction"));

export enum ClaimsRoutes {
  Loading = "Loading",
  SelectOption = "ChooseSettlementOption",
  BankDetails = "EnterBankDetails",
  Verification = "Verification",
  Confirmation = "Confirmation",
}

export enum UtilityRoutes {
  SessionTimeout = "408",
  SystemUnavailable = "500",
  Expired = "LinkExpired",
  RoadBlock = "RoadBlock",
  VerifyRoadBlock = "VerifyRoadBlock",
  ClaimTransactionNotFound = "ClaimTransactionNotFound",
  NoFurtherAction = "NoFurtherAction",
}

export enum ClaimsPageNames {
  Loading = "Loading",
  SelectOption = "Choose preferred option",
  BankDetails = "Enter your bank details",
  Verification = "Verify it's you",
  Confirmation = "Confirmation",
}

export enum UtilityPageNames {
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
  Expired = "Link expired",
  RoadBlock = "Road block",
  VerifyRoadBlock = "Road block",
  ClaimTransactionNotFound = "Claim transaction not found",
  NoFurtherAction = "No further action",
}

export enum UtilityPageHeadings {
  Default = "Uh oh!",
}

const getClaimsRouteUrl = (route: ClaimsRoutes) => `${process.env.REACT_APP_HOMEPAGE}/${route}`;
const getUtilityRouteUrl = (route: UtilityRoutes) => `${process.env.REACT_APP_HOMEPAGE}/${route}`;

export const LOADING_PAGE_URL = getClaimsRouteUrl(ClaimsRoutes.Loading);
export const SELECT_OPTION_PAGE_URL = getClaimsRouteUrl(ClaimsRoutes.SelectOption);
export const BANK_DETAILS_PAGE_URL = getClaimsRouteUrl(ClaimsRoutes.BankDetails);
export const VERIFICATION_PAGE_URL = getClaimsRouteUrl(ClaimsRoutes.Verification);
export const CONFIRMATION_PAGE_URL = getClaimsRouteUrl(ClaimsRoutes.Confirmation);

export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.SystemUnavailable);
export const UTILITY_LINK_EXPIRED_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.Expired);
export const UTILITY_ROAD_BLOCK_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.RoadBlock);
export const UTILITY_VERIFY_ROAD_BLOCK_PAGE_URL = getUtilityRouteUrl(UtilityRoutes.VerifyRoadBlock);
export const UTILITY_CLAIM_TRANSACTION_NOTFOUND = getUtilityRouteUrl(UtilityRoutes.ClaimTransactionNotFound);
export const UTILITY_NO_FURTHER_ACTION = getUtilityRouteUrl(UtilityRoutes.NoFurtherAction);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const claimsRoutesInfo: Record<ClaimsRoutes, RouteInformation> = {
  [ClaimsRoutes.Loading]: {
    key: ClaimsRoutes.Loading,
    path: LOADING_PAGE_URL,
    name: ClaimsPageNames.Loading,
    element: <LandingPage />,
  },
  [ClaimsRoutes.SelectOption]: {
    key: ClaimsRoutes.SelectOption,
    path: SELECT_OPTION_PAGE_URL,
    name: ClaimsPageNames.SelectOption,
    element: <ChooseSettlementOption />,
  },
  [ClaimsRoutes.BankDetails]: {
    key: ClaimsRoutes.BankDetails,
    path: BANK_DETAILS_PAGE_URL,
    name: ClaimsPageNames.BankDetails,
    element: <EnterBankDetails />,
  },
  [ClaimsRoutes.Verification]: {
    key: ClaimsRoutes.Verification,
    path: VERIFICATION_PAGE_URL,
    name: ClaimsPageNames.Verification,
    element: <OtpVerification />,
  },
  [ClaimsRoutes.Confirmation]: {
    key: ClaimsRoutes.Confirmation,
    path: CONFIRMATION_PAGE_URL,
    name: ClaimsPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const allFormRoutes: RouteInformation[] = [
  claimsRoutesInfo[ClaimsRoutes.SelectOption],
  claimsRoutesInfo[ClaimsRoutes.Verification],
  claimsRoutesInfo[ClaimsRoutes.BankDetails],
  claimsRoutesInfo[ClaimsRoutes.Confirmation],
];

export const loadingRoutes: RouteInformation[] = [claimsRoutesInfo[ClaimsRoutes.Loading]];

export const eftRoutes: RouteInformation[] = [
  claimsRoutesInfo[ClaimsRoutes.Verification],
  claimsRoutesInfo[ClaimsRoutes.BankDetails],
  claimsRoutesInfo[ClaimsRoutes.Confirmation],
];

export const cashSettlementAcceptRoutes: RouteInformation[] = [
  claimsRoutesInfo[ClaimsRoutes.SelectOption],
  claimsRoutesInfo[ClaimsRoutes.Verification],
  claimsRoutesInfo[ClaimsRoutes.BankDetails],
  claimsRoutesInfo[ClaimsRoutes.Confirmation],
];

export const cashSettlementDeclineRoutes: RouteInformation[] = [
  claimsRoutesInfo[ClaimsRoutes.SelectOption],
  claimsRoutesInfo[ClaimsRoutes.Confirmation],
];

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.Expired,
    path: UTILITY_LINK_EXPIRED_PAGE_URL,
    name: UtilityPageNames.Expired,
    element: <LinkExpired />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.RoadBlock,
    path: UTILITY_ROAD_BLOCK_PAGE_URL,
    name: UtilityPageNames.RoadBlock,
    element: <RoadBlock roadBlockType={ROADBLOCK_TYPE_ERROR} />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.VerifyRoadBlock,
    path: UTILITY_VERIFY_ROAD_BLOCK_PAGE_URL,
    name: UtilityPageNames.VerifyRoadBlock,
    element: <RoadBlock roadBlockType={ROADBLOCK_TYPE_VERIFY} />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.ClaimTransactionNotFound,
    path: UTILITY_CLAIM_TRANSACTION_NOTFOUND,
    name: UtilityPageNames.ClaimTransactionNotFound,
    element: <ClaimTransactionNotFound />,
    heading: UtilityPageHeadings.Default,
  },
  {
    key: UtilityRoutes.NoFurtherAction,
    path: UTILITY_NO_FURTHER_ACTION,
    name: UtilityPageNames.NoFurtherAction,
    element: <NoFurtherAction />,
    heading: UtilityPageHeadings.Default,
  },
];

export const allRoutes = [...allFormRoutes, ...utilityRoutes];

export interface UseRoutesResult {
  currentStep: number;
  totalSteps: number;
  onBack: () => void;
}
