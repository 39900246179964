export const SESSION_KEY_PREFIX = "RAC_CEFT_";
export const SESSION_KEY_PAGE_PREFIX = `${SESSION_KEY_PREFIX}PAGE_`;
export const SESSION_KEY_HAS_USER_REQUESTED_CALL = `${SESSION_KEY_PREFIX}ATOM_HAS_REQUESTED_CALL`;

export const NON_BREAKING_SPACE = "\u00a0";
export const ACCEPT_SETTLEMENT = "accept";
export const DECLINE_SETTLEMENT = "decline";

export const ROUTE_LOADING = "Loading";
export const ROUTE_SELECT_OPTION = "ChooseSettlementOption";
export const ROUTE_ENTER_EFT = "EnterBankDetails";
export const ROUTE_CONFIRMATION = "Confirmation";
export const ROUTE_VERIFICATION = "Verification";
export const ROUTE_TIMEOUT = "408";
export const ROUTE_ERROR = "500";
export const ROUTE_EXPIRED = "LinkExpired";
export const ROUTE_ROADBLOCK = "RoadBlock";
export const ROUTE_ROADBLOCK_VERIFY = "VerifyRoadBlock";
export const ROUTE_CLAIM_TRANSACTION_NOT_FOUND = "ClaimTransactionNotFound";
export const ROUTE_NO_FURTHER_ACTION = "NoFurtherAction";

export const ROADBLOCK_TYPE_VERIFY = "verify";
export const ROADBLOCK_TYPE_ERROR = "error";

export const CHANNEL_TYPE_MOBILE = "sms";
export const CHANNEL_TYPE_LANDLINE = "call";

export const VERIFICATION_CODE_LENGTH = 6;

export const MAX_OTP_ATTEMPTS = 6;
export const MAX_OTP_CODE_REQUESTS = 6;

export const HISTORY_ACTION_POP = "POP";

export const HTTP_STATUS_TOO_MANY_REQUESTS = 429;
export const HTTP_STATUS_OTP_EXPIRED = 404;
export const HTTP_STATUS_BAD_REQUEST = 400;
export const HTTP_STATUS_OK = 200;

export const VERIFICATION_STATUS_APPROVED = "approved";
export const VERIFICATION_STATUS_PENDING = "pending";

export const WRONG_OTP_ERROR = "Sorry, that code didn't match. Please try again";
export const EXPIRED_OTP_ERROR = "Sorry, your code has expired. Please request a new code.";
